import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";

import Axios from "../..//services/Api.js";

import { sortOptions } from "../../data/formOptions";

import DropDownInput from "../DropDownInput/DropDownInput";

const SearchFilters = (props) => {
  const [selectedLibrary, setSelectedLibrary] = useState([
    // {
    // 	label: 'Todas las bibliotecas',
    // 	value: '',
    // },
  ]);
  const [selectedLanguage, setSelectedLanguage] = useState([
    // {
    // 	label: 'Todas los idiomas',
    // 	value: '',
    // },
  ]);
  const [selectedMaterial, setSelectedMaterial] = useState([
    // {
    // 	label: 'Todas los materiales',
    // 	value: '',
    // },
  ]);
  const [libraries, setLibraries] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [languages, setLanguages] = useState([]);

  const { search } = useLocation();

  useEffect(() => {
    Axios.GetBibliotecas()
      .then((res) => {
        if (res.data.result) {
          setLibraries(res.data.data);
        } else {
          setLibraries([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLibraries([]);
      });
    Axios.GetMateriales()
      .then((res) => {
        if (res.data.result) {
          setMaterials(res.data.data);
        } else {
          setMaterials([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setMaterials([]);
      });
    Axios.GetLanguages()
      .then((res) => {
        if (res.data.result) {
          setLanguages(res.data.data);
        } else {
          setLanguages([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setLanguages([]);
      });
    return () => {
      setLanguages([]);
      setLibraries([]);
      setMaterials([]);
    };
  }, []);

  useEffect(() => {
    const groupParamsByKey = (params) =>
      [...params.entries()].reduce((acc, [key, val]) => {
        if (acc.hasOwnProperty(key)) {
          // if the current key is already an array, we push the value to it
          if (Array.isArray(acc[key])) {
            acc[key] = [...acc[key], val];
          } else {
            // if it's not an array, we will convert it into an array and add the current value to it
            acc[key] = [acc[key], val];
          }
        } else if (val !== "") {
          // plain assignment if no special case is present
          acc[key] = [val];
        }

        return acc;
      }, {});
    const paramsToObject = (params) => {
      try {
        const urlParams = new URLSearchParams(params);
        const paramsObj = groupParamsByKey(urlParams);
        return paramsObj;
      } catch (e) {
        console.log(e);
        return {};
      }
    };

    const capitalLetter = (str) => {
      str = str.split(" ");

      for (let i = 0, x = str.length; i < x; i++) {
        str[i] = str[i][0].toUpperCase() + str[i].substr(1);
      }

      return str.join(" ");
    };

    const params = paramsToObject(search);
    console.log(params);
    params?.biblioteca &&
      setSelectedLibrary(
        params?.biblioteca.map((item) => ({
          label: capitalLetter(item),
          value: item.toLowerCase(),
        }))
      );
    params?.idioma &&
      setSelectedLanguage(
        params?.idioma?.map((item) => ({ label: item, value: item }))
      );
    params?.material &&
      setSelectedMaterial(
        params?.material.map((item) => ({ label: item, value: item }))
      );
  }, [search]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 mb-3 form-group">
          <label htmlFor="biblioteca">Biblioteca:</label>
          <Select
            isMulti
            name="biblioteca"
            options={libraries?.map((library) => ({
              label: library?.nombre_biblioteca,
              value: library?.nombre_biblioteca.toLowerCase(),
            }))}
            value={selectedLibrary}
            onChange={(e) => setSelectedLibrary(e)}
            placeholder="Todas las bibliotecas"
          />
        </div>
        <div className="col-12 mb-3 form-group">
          <label htmlFor="idioma">Idioma:</label>
          <Select
            isMulti
            name="idioma"
            options={languages?.map((languge) => ({
              label: languge?.idioma,
              value: languge?.codigo,
            }))}
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e)}
            placeholder="Todos los idiomas"
          />
        </div>
        <div className="col-12 form-group">
          <label htmlFor="material">Material:</label>
          <Select
            isMulti
            name="material"
            options={materials?.map((material) => ({
              label: material?.nombre_material,
              value: material?.nombre_material,
            }))}
            value={selectedMaterial}
            onChange={(e) => {
              console.log(e);
              setSelectedMaterial(e);
            }}
            placeholder="Todos los materiales"
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12 form-group">
          <label htmlFor="sortby">Ordenar por:</label>
          <DropDownInput name="sortby" options={sortOptions} />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          <button className="btn btn-success col-12">Aplicar filtros</button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchFilters;
