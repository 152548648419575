import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import SearchBar from "../../components/SearchBar/SearchBar";
import DropDownInput from "../../components/DropDownInput/DropDownInput";
import InventoryItem from "../../components/InventoryItem/InventoryItem";

//import styles from './RecordScreen.module.css';
import Axios from "../../services/Api";
// import Toast from "../../components/Toast/Toast";
import QuoteModal from "../../components/QuoteModal/QuoteModal";
// import LoadingBox from "../../components/LoadingBox/LoadingBox";
import app_params from "../../config/params";
import ShareModal from "../../components/ShareModal/ShareModal";

const recordDownload = async (record, file) => {
  const { data } = await Axios.DownloadHit({
    record: record,
  });

  console.log(data);
  window.open(file, "_blank");
};

const RecordScreen = (props) => {
  const { id } = useParams();
  const { search } = useLocation();
  const [record, setRecord] = useState({});
  const [result_header, setResultHeader] = useState({});
  const [selectedLibrary, setSelectedLibrary] = useState("");
  const [showMARC, setShowMARC] = useState(false);
  //const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [quotes, setQuotes] = useState({});

  const getQuotes = (event) => {
    event.preventDefault()
    id && Axios.GetQuotes({ urlParams: { id: id } })
        .then((res) => {
          if (res.data?.success && res.data?.success === "true") {
            setQuotes(res.data?.quotes);
          } else {
            setQuotes({});
          }
        })
        .catch((error) => {
          console.log(error);
          setQuotes({});
        });
  }

  const handleLibraryChange = (event) => {
    setSelectedLibrary(event.target.value);
  };

  useEffect(() => {
    const groupParamsByKey = (params) =>
      [...params.entries()].reduce((acc, [key, val]) => {
        if (acc.hasOwnProperty(key)) {
          // if the current key is already an array, we push the value to it
          if (Array.isArray(acc[key])) {
            acc[key] = [...acc[key], val];
          } else {
            // if it's not an array, we will convert it into an array and add the current value to it
            acc[key] = [acc[key], val];
          }
        } else {
          // plain assignment if no special case is present
          acc[key] = val;
        } // else
        return acc;
      }, {});
    const paramsToObject = (params) => {
      const urlParams = new URLSearchParams(params);
      const paramsObj = groupParamsByKey(urlParams);
      return paramsObj;
    };
    const params = paramsToObject(search);

    Axios.GetRecord({ urlParams: { id }, params: { ...params } })
      .then((res) => res.data)
      .then((data) => {
        if (data?.success === "true") {
          console.log(data);
          setRecord(data.record);
          setResultHeader(data.result_header);
          if (data?.record?.total_copies > 0) {
            setSelectedLibrary(
              data?.record?.inventory[0]?.library ||
                data?.record?.inventory?.library ||
                ""
            );
          } // if
        } else {
          window.location.replace(`${app_params.basepath}/404`);
        }
      })
      .catch((e) => {
        console.log(e);
        setRecord({});
        setResultHeader({});
        setSelectedLibrary({});
      });
    return () => {
      setRecord({});
    };
  }, [id, search]);

  const goTo = (record) => {
    const num_record = result_header?.pagination[record];
    const recnum =
      record === "previous_record"
        ? +result_header?.pagination?.current_record - 1
        : +result_header?.pagination?.current_record + 1;
    const urlParams = new URLSearchParams(result_header?.searchargs);
    const paramsObj = Object.fromEntries(urlParams);
    paramsObj.recnum = recnum;
    const params = Object.entries(paramsObj || {})
      .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
      .join("&");
    window.location.href = `${app_params.basepath}/record/${num_record}?${params}`;
  };

  // const shareRecord = () => {
  //   //TODO: Modal to share in different social media pages
  //   navigator.clipboard.writeText(
  //     `${window.location.origin}${app_params.basepath}/record/${id}`
  //   );
  // };

  // const showCover = async () => {
  //   try {
  //     const { data } = await Axios.GetCover({ urlParams: { id } });
  //     if (data?.success && data?.cover) {
  //       window.open(data.cover, `Portada ${id}`, "_blank");
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <section className="container">
      <div className="row mt-lg-3 ms-lg-1">
        <div className="col-12 col-lg-9">
          <div className="row mb-lg-2 shadow-sm rounded">
            <div className="col-12 col-lg-2 p-2 bg-white d-none d-lg-block rounded-start">
              <div
                onClick={() => navigate(-1)}
                className="btn btn-outline-secondary col-12"
              >
                <span>
                  <i className="fas fa-arrow-left"></i> Atrás
                </span>
              </div>
            </div>
            <div
              className={
                result_header?.pagination !== "null"
                  ? "col-12 col-lg-6 col-xxl-7 p-2 bg-white mb-2 mb-lg-0"
                  : "col-12 col-lg-10 col-xxl-10 p-2 bg-white mb-2 mb-lg-0"
              }
            >
              <form action={`${app_params.basepath}/query`}>
                <SearchBar />
              </form>
            </div>
            <div className="col-12 p-2 bg-white mb-2 mb-lg-0 d-lg-none d-flex row shadow-sm rounded mx-0">
              {record?.available_copies && record?.available_copies !== "0" && (
                <a href="#inventory" className="btn btn-success py-2 col-12">
                  <span>
                    <i className="fas fa-check"></i> Disponible{" "}
                    <u>ver&nbsp;copias</u>
                  </span>
                </a>
              )}
              {record?.available_copies && record?.available_copies === "0" && (
                <a href="#inventory" className="btn btn-danger p-2 col-12">
                  <span>
                    <i className="fas fa-times-circle"></i> No disponible
                  </span>
                </a>
              )}
              {record?.file && record?.file !== "null" && (
                <button
                  className="btn btn-primary p-2 col-12 mt-2"
                  onClick={() => recordDownload(id, record?.file)}
                >
                  <span>
                    <i className="fas fa-globe-americas"></i> En línea
                  </span>
                </button>
              )}
            </div>
            {result_header?.pagination !== "null" && (
              <div className="col-12 col-lg-4 col-xxl-3 px-2 pb-2 py-lg-2 bg-white rounded-end">
                <div className="btn-group col-12 mt-2 mt-lg-0" role="group">
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => goTo("previous_record")}
                    disabled={result_header?.pagination?.current_record === "1"}
                  >
                    <span>
                      <i className="fas fa-chevron-left"></i>
                    </span>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary disabled"
                  >
                    {result_header?.pagination && (
                      <React.Fragment>{`${result_header?.pagination?.current_record} de ${result_header?.pagination?.total_records}`}</React.Fragment>
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-secondary"
                    onClick={() => goTo("next_record")}
                    disabled={
                      result_header?.pagination?.current_record ===
                      result_header?.pagination?.total_records
                    }
                  >
                    <span>
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="row bg-white p-lg-2 mb-3 shadow-sm rounded">
            <div className="col-12 col-lg-3">
              <div className="row text-lg-center">
                <div className="col-5 col-lg-12 mb-2 py-2">
                  <a href={record?.cover} target="_blank" rel="noreferrer">
                    <img
                      type="button"
                      src={record?.thumb}
                      alt="thumb"
                      className={`img-thumbnail rounded w-100 w-md-50 w-lg-75`}
                    />
                  </a>
                </div>
                <div className="col-7 col-lg-12">
                  <div className="row d-flex flex-column justify-content-center h-75">
                    <div className="col-12">
                      {record?.preview_file !== "null" && (
                        <a
                          className="btn btn-sm btn-outline-secondary col-12 mb-2"
                          href={record?.preview_file}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="me-2">
                            <i className="fas fa-align-left"></i>
                          </span>
                          Contenido
                        </a>
                      )}
                      {/* <button
                        className="btn btn-sm btn-outline-secondary col-12 mb-2"
                        id="shareRecordButton"
                        onClick={() => shareRecord()}
                      >
                        <span className="me-2">
                          <i className="fas fa-share-alt"></i>
                        </span>
                        Compartir
                      </button> */}
                      <button
                        className="btn btn-sm btn-outline-secondary col-12  mb-2"
                        data-bs-toggle="modal"
                        data-bs-target={`#shareModal-${id}`}
                      >
                        <span className="me-2">
                          <i className="fas fa-share-alt"></i>
                        </span>
                        Compartir
                      </button>
                      <button
                        className="btn btn-sm btn-outline-secondary col-12"
                        data-bs-toggle="modal"
                        data-bs-target={`#quoteModal-${id}`}
                        onClick={getQuotes}
                      >
                        <span className="me-2">
                          <i className="fas fa-quote-left"></i>
                        </span>
                        Referencia
                      </button>
                    </div>
                  </div>
                  <div className="row flex-column justify-content-center h-25">
                    <div className="col-12 d-lg-none d-flex flex-row-reverse">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="showMARC"
                          checked={showMARC}
                          onChange={(event) =>
                            setShowMARC(event.target.checked)
                          }
                        />
                        <label className="form-check-label" htmlFor="showMARC">
                          Vista MARC
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <div className="row mb-2">
                <div className="col-12 d-none d-lg-flex flex-row-reverse py-2">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="showMARC"
                      checked={showMARC}
                      onChange={(event) => setShowMARC(event.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="showMARC">
                      Vista MARC
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  {!showMARC &&
                    record?.marc?.tags &&
                    Object.entries(record.marc.tags)?.map(
                      ([_, tag_value], index) => {
                        if (!Array.isArray(tag_value))
                          return (
                            <div className="row mb-1" key={index}>
                              <div className="col-4 col-lg-3 col-xxl-2">
                                <b>{tag_value.name}</b>:
                              </div>
                              <div className="col-8 col-lg-9 col-xxl-10 text-break">
                                {tag_value?.searchargs !== "NULL" && (
                                  <a
                                    href={`${app_params.basepath}/query?${tag_value?.searchargs}`}
                                  >
                                    {tag_value?.subfields &&
                                    !Array.isArray(tag_value?.subfields)
                                      ? tag_value.subfields.value
                                      : tag_value.subfields
                                          ?.map((subfield) => subfield.value)
                                          .join(" ")}
                                  </a>
                                )}
                                {tag_value?.searchargs === "NULL" && (
                                  <React.Fragment>
                                    {tag_value?.subfields &&
                                    !Array.isArray(tag_value?.subfields)
                                      ? tag_value.subfields.value
                                      : tag_value.subfields
                                          ?.map((subfield) => subfield.value)
                                          .join(" ")}
                                  </React.Fragment>
                                )}
                              </div>
                            </div>
                          );
                        else
                          return (
                            <div className="row mb-1" key={index}>
                              <div className="col-4 col-lg-3 col-xxl-2">
                                <b>{tag_value[0]?.name}</b>:
                              </div>
                              <div className="col-8 col-lg-9 col-xxl-10">
                                {tag_value.map((tag_val, index) => (
                                  <div
                                    key={index}
                                    className="m-0 p-0 text-break"
                                  >
                                    {tag_val?.searchargs !== "NULL" && (
                                      <a
                                        href={`${app_params.basepath}/query?${tag_val?.searchargs}`}
                                      >
                                        {tag_val?.subfields &&
                                        !Array.isArray(tag_val?.subfields)
                                          ? tag_val.subfields.value
                                          : tag_val.subfields
                                              ?.map(
                                                (subfield) => subfield.value
                                              )
                                              .join(" ")}
                                      </a>
                                    )}
                                    {tag_val?.searchargs === "NULL" && (
                                      <React.Fragment>
                                        {tag_val?.subfields &&
                                        !Array.isArray(tag_val?.subfields)
                                          ? tag_val.subfields.value
                                          : tag_val.subfields
                                              ?.map(
                                                (subfield) => subfield.value
                                              )
                                              .join(" ")}
                                      </React.Fragment>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                      }
                    )}
                  {showMARC && record?.marc?.leader && (
                    <div className="col-12">
                      <React.Fragment>
                        <b>Leader Tag</b>: {record?.marc?.leader}
                      </React.Fragment>
                    </div>
                  )}
                  {showMARC &&
                    record?.marc?.tags &&
                    Object.entries(record.marc.tags)?.map(
                      ([_, datafield], index) => {
                        if (!Array.isArray(datafield))
                          return (
                            <div className="row mb-1" key={index}>
                              <div className="col-12 text-break">
                                {datafield?.subfields &&
                                  !Array.isArray(datafield?.subfields) && (
                                    <React.Fragment
                                      key={`${datafield.tag}${index}`}
                                    >
                                      <b>{datafield.tag}</b>:{" "}
                                      <b> {datafield?.subfields?.code}| </b>
                                      {datafield?.subfields?.value}
                                    </React.Fragment>
                                  )}
                                {datafield?.subfields &&
                                  Array.isArray(datafield?.subfields) && (
                                    <div
                                      key={`${datafield.tag}${index}`}
                                      className="row p-0 m-0"
                                    >
                                      <b className="p-0 m-0 w-auto">
                                        {datafield.tag}
                                      </b>
                                      {`: `}
                                      {datafield.subfields?.map(
                                        (subfield, index) => (
                                          <div
                                            className="p-0 m-0 w-auto"
                                            key={index}
                                          >
                                            <b>{` ${subfield?.code}| `}</b>
                                            {subfield?.value}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                              </div>
                              <div className="col-9 col-xxl-10"></div>
                            </div>
                          );
                        else
                          return (
                            <div className="row mb-1" key={index}>
                              <div className="col-12">
                                {datafield.map((tag_val, index) => {
                                  if (
                                    tag_val?.subfields &&
                                    !Array.isArray(tag_val?.subfields)
                                  )
                                    return (
                                      <div
                                        className="row m-0 p-0 w-auto"
                                        key={index}
                                      >
                                        <b className="p-0 m-0 w-auto">
                                          {tag_val.tag}
                                        </b>
                                        :{" "}
                                        <b className="p-0 m-0 w-auto">
                                          {" "}
                                          {tag_val?.subfields?.code}|{" "}
                                        </b>
                                        {tag_val?.subfields?.value}
                                      </div>
                                    );
                                  else
                                    return (
                                      <div
                                        key={`${tag_val.tag}${index}`}
                                        className="row p-0 m-0"
                                      >
                                        <b className="p-0 m-0 w-auto">
                                          {tag_val.tag}
                                        </b>
                                        {`: `}
                                        {tag_val.subfields?.map(
                                          (subfield, index) => (
                                            <div
                                              className="p-0 m-0 w-auto"
                                              key={index}
                                            >
                                              <b>{` ${subfield?.code}| `}</b>
                                              {subfield?.value}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    );
                                })}
                              </div>
                            </div>
                          );
                      }
                    )}
                </div>
              </div>
            </div>
          </div>
          {record?.inventory && (
            <div
              className="row bg-white p-2 shadow-sm rounded mb-3"
              id="inventory"
            >
              <div className="col-12">
                <h5 className="my-2">Ubicación de copias</h5>
                <div className="row">
                  <div className="col-12 col-lg-3 mb-2">
                    <div className="form-group">
                      <label htmlFor="biblio">Biblioteca:</label>
                      {record?.inventory && Array.isArray(record?.inventory) && (
                        <DropDownInput
                          onChange={handleLibraryChange}
                          name="biblio"
                          options={record?.inventory
                            ?.map((item, index) => ({
                              value: item.library,
                              displayText: item.library,
                              selected: index === 0,
                            }))
                            .filter(
                              (value, index, self) =>
                                self.findIndex(
                                  (v) => v.value === value.value
                                ) === index
                            )}
                        />
                      )}
                      {record?.inventory && !Array.isArray(record?.inventory) && (
                        <DropDownInput
                          onChange={handleLibraryChange}
                          name="biblio"
                          options={[
                            {
                              value: record?.inventory?.library,
                              displayText: record?.inventory?.library,
                              selected: true,
                            },
                          ]}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-9">
                    {record?.inventory &&
                      Array.isArray(record?.inventory) &&
                      record?.inventory
                        .filter((item) => item.library === selectedLibrary)
                        .map((item, index) => {
                          return (
                            <InventoryItem
                              item={item}
                              key={index}
                              record={id}
                            />
                          );
                        })}
                    {record?.inventory && !Array.isArray(record?.inventory) && (
                      <InventoryItem
                        item={record?.inventory}
                        key={0}
                        record={id}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-3 d-none d-lg-block">
          <div className="bg-white p-2 text-center sticky-top shadow-sm rounded">
            <div className="row">
              <div className="col-12">
                <h5 className="mx-0 mb-0 mt-2">Opciones de acceso</h5>
              </div>
            </div>
            <hr />
            <div className="row mb-2">
              <div className="col-12">
                <h5 className="m-0">
                  {record?.available_copies &&
                    record?.available_copies !== "0" && (
                      <a
                        href="#inventory"
                        className="btn btn-success p-2 col-10"
                      >
                        <span>
                          <i className="fas fa-check"></i> Disponible{" "}
                          <u>ver&nbsp;copias</u>
                        </span>
                      </a>
                    )}
                  {record?.available_copies &&
                    record?.available_copies === "0" && (
                      <a
                        href="#inventory"
                        className="btn btn-danger p-2 col-10"
                      >
                        <span>
                          <i className="fas fa-times-circle"></i> No disponible
                        </span>
                      </a>
                    )}
                </h5>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                {record?.file && record?.file !== "null" && (
                  <button
                    className="btn btn-primary p-2 col-10"
                    onClick={() => recordDownload(id, record?.file)}
                  >
                    <span className="me-2">
                      <i className="fas fa-globe-americas"></i>
                    </span>
                    En línea
                  </button>
                )}
              </div>
            </div>
            <hr className="my-2" />
            <div className="row mb-1">
              <div className="col-12">
                {record?.total_copies > 0 && (
                  <React.Fragment>
                    {record?.access_summary?.physical === "1" && (<p className="mb-0">1 copia física</p>)}
                    {record?.access_summary?.physical > 1 && (<p className="mb-0">{`${record?.access_summary?.physical} copias físicas`}</p>)}
                    {record?.access_summary?.digital === "1" && (<p className="mb-0">1 copia digital</p>)}
                    {record?.access_summary?.digital > 1 && (<p className="mb-0">{`${record?.access_summary?.digital} copias digitales`}</p>)}
                    <p className="mb-0">{`${record?.available_copies} de ${record?.total_copies} copias disponibles`}</p>
                  </React.Fragment>
                )}
                {record?.total_copies === "0" && (
                  <React.Fragment>Este registro no tiene copias</React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Toast
        buttonID="shareRecordButton"
        toastID="shareRecordToast"
        variant="secondary"
      >
        Enlace de título copiado en el portapapeles
      </Toast> */}
      <QuoteModal modalID={`quoteModal-${id}`} item={{ id }} quotes={quotes} />
      <ShareModal
        id={`shareModal-${id}`}
        url={`${window.location.origin}${app_params.basepath}/record/${id}`}
        quote={record?.share_quote}
      />
    </section>
  );
};

export default RecordScreen;
