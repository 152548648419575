const NotFoundScreen = (props) => {
	return (
		<section className='container'>
			<div className='bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded text-center'>
				<h3>Error 404: Página no encontrada</h3>
			</div>
		</section>
	);
};

export default NotFoundScreen;
