import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

//import styles from "./Pagination.module.css";

const Pagination = (props) => {
  const { search } = useLocation();
  const [params, setParams] = useState("");
  const {
    result: { result_header },
  } = useSelector((state) => state.searchResult);
  useEffect(() => {
    const paramsToObject = (params) => {
      if (params === "") return {};
      const urlParams = new URLSearchParams(params);
      const paramsObj = Object.fromEntries(urlParams);
      paramsObj.page && delete paramsObj.page;
      const paramsResult = Object.entries(paramsObj || {})
        .map(([key, val]) => `${key}=${encodeURIComponent(val)}`)
        .join("&");
      return paramsResult;
    };
    setParams(paramsToObject(search));

    return () => {
      setParams("");
    };
  }, [search]);

  return (
    <div className="d-flex justify-content-between p-0">
      <div className="d-flex flex-column justify-content-center ms-2">
        {result_header?.total_results !== "NaN"
          ? result_header?.total_results
          : "0"}{" "}
        resultados
      </div>
      <nav>
        <ul className="pagination m-0">
          {result_header?.pagination?.previous_page &&
            result_header?.pagination?.previous_page !== "null" && (
              <li className="page-item">
                <a
                  className="page-link"
                  href={`?${params}&page=${result_header?.pagination?.previous_page}`}
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
            )}
          {result_header?.pagination?.page_list &&
            Array.isArray(result_header?.pagination?.page_list) &&
            result_header?.pagination?.page_list?.map((page, i) => (
              <li
                className={`page-item ${
                  page?.active === "false" ? "active" : ""
                }`}
                key={i}
              >
                <a
                  className="page-link"
                  key={i}
                  disabled
                  href={
                    page?.active === "false"
                      ? "#"
                      : `?${params}&page=${page?.page_number}`
                  }
                >
                  {page?.page_number}
                </a>
              </li>
            ))}
          {result_header?.pagination?.next_page !== "null" && (
            <li className="page-item">
              <a
                className="page-link"
                href={`?${params}&page=${result_header?.pagination?.next_page}`}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
