const ApiURLs = {
  baseURL: "https://enca.glifos.net/api/opac/v1",
  SimpleSearch: {
    href: "/search/query",
    method: "get",
  },
  GetRecord: {
    href: "/record/:id",
    method: "get",
    urlParams: ["id"],
  },
  GetBibliotecas: {
    href: "/biblioteca",
    method: "get",
  },
  GetMateriales: {
    href: "/material",
    method: "get",
  },
  GetLanguages: {
    href: "/language",
    method: "get",
  },
  GetQuotes: {
    href: "/record/:id/quotes",
    method: "get",
    urlParams: ["id"],
  },
  GetLatestAcquisitions: {
    href: "/acquisitions/latest",
    method: "get",
  },
  GetAcquisitions: {
    href: "/acquisitions",
    method: "get",
  },
  GetVisits: {
    href: "/site/visits",
    method: "get",
  },
  DownloadHit: {
    href: "/site/downloads",
    method: "post",
    requires: ["record"],
  },
  GetCover: {
    href: "/record/:id/cover",
    method: "get",
    urlParams: ["id"],
  },
  UserLogin: {
    href: "/v1/auth/authenticate",
    method: "post",
    requires: ["catalogador", "password"],
  },
  UserRefresh: {
    href: "/v1/auth/refresh-token",
    method: "post",
    requires: ["refresh_token"],
  },
};

export default ApiURLs;
